import React, { useEffect, useState } from "react";
import RolesComponent from "../components/RolesComponent";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, postAdminRoleAssignmentDetails } from "../store/actions";
import { ToastContainer, toast } from "react-toastify";
import HomeContainer from "./HomeContainer";

function RolesContainer() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const [userData, setUserData] = useState([]);

  const fetchGetUsersDetails = useSelector(
    (state) => state.fetchGetUsersDetails
  );
  const fetchPostAdminRoleAssingmentData = useSelector(
    (state) => state.fetchPostAdminRoleAssingmentData
  );
  useEffect(() => {
    if (fetchGetUsersDetails && fetchGetUsersDetails.users) {
      setUserData(fetchGetUsersDetails.users);
    }
  }, [fetchGetUsersDetails]);

  useEffect(() => {
    if (fetchPostAdminRoleAssingmentData) {
      if (fetchPostAdminRoleAssingmentData.status === 200) {
        toast.success("User role updated sucessfully");
      } else if (fetchPostAdminRoleAssingmentData.status === 500) {
        toast.warning("Select a permission to assing the Employee");
      }
    }
  }, [fetchPostAdminRoleAssingmentData]);

  const handleFieldChange = (event, userId, fieldName) => {
    const updatedData = userData.map((user) =>
      user.name === userId ? { ...user, [fieldName]: event.target.value } : user
    );
    setUserData(updatedData);
  };

  const handleSave = () => {
    console.log("Saving data:", userData);
    dispatch(postAdminRoleAssignmentDetails(userData));
  };

  const userDataWithIds = userData.map((user, index) => ({
    ...user,
    id: index + 1,
  }));
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "first_name",
      headerName: "User Name",
      width: 250,
    },
    {
      field: "strategyonboarding",
      headerName: "Strategy Onboarding",
      width: 200,
      renderCell: (params) => (
        <div className="roles-parent-input-container">
          <div className="roles-child-input-container">
            <input
              id={`onboarding-read-${params.id}`}
              type="radio"
              name={`onboarding-${params.id}`}
              value="read"
              checked={params.value === "read"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "strategyonboarding")
              }
            />
            <label
              htmlFor={`onboarding-read-${params.id}`}
              className="roles-radio-label"
            >
              R
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`onboarding-write-${params.id}`}
              type="radio"
              name={`onboarding-${params.id}`}
              value="write"
              checked={params.value === "write"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "strategyonboarding")
              }
            />
            <label
              htmlFor={`onboarding-write-${params.id}`}
              className="roles-radio-label"
            >
              W
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`onboarding-admin-${params.id}`}
              type="radio"
              name={`onboarding-${params.id}`}
              value="admin"
              checked={params.value === "admin"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "strategyonboarding")
              }
            />
            <label
              htmlFor={`onboarding-admin-${params.id}`}
              className="roles-radio-label"
            >
              A
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`onboarding-none-${params.id}`}
              type="radio"
              name={`onboarding-${params.id}`}
              value="none"
              checked={params.value === "none"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "strategyonboarding")
              }
            />
            <label
              htmlFor={`onboarding-none-${params.id}`}
              className="roles-radio-label"
            >
              N
            </label>
          </div>
        </div>
      ),
    },
    {
      field: "strategyvalidation",
      headerName: "Strategy Validation",
      type: "email",
      width: 200,
      renderCell: (params) => (
        <div className="roles-parent-input-container">
          <div className="roles-child-input-container">
            <input
              id={`validation-read-${params.id}`}
              type="radio"
              name={`validation-${params.id}`}
              value="read"
              checked={params.value === "read"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "strategyvalidation")
              }
            />
            <label
              htmlFor={`validation-read-${params.id}`}
              className="roles-radio-label"
            >
              R
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`validation-write-${params.id}`}
              type="radio"
              name={`validation-${params.id}`}
              value="write"
              checked={params.value === "write"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "strategyvalidation")
              }
            />
            <label
              htmlFor={`validation-write-${params.id}`}
              className="roles-radio-label"
            >
              W
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`validation-admin-${params.id}`}
              type="radio"
              name={`validation-${params.id}`}
              value="admin"
              checked={params.value === "admin"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "strategyvalidation")
              }
            />
            <label
              htmlFor={`validation-admin-${params.id}`}
              className="roles-radio-label"
            >
              A
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`validation-none-${params.id}`}
              type="radio"
              name={`validation-${params.id}`}
              value="none"
              checked={params.value === "none"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "strategyvalidation")
              }
            />
            <label
              htmlFor={`validation-none-${params.id}`}
              className="roles-radio-label"
            >
              N
            </label>
          </div>
        </div>
      ),
    },
    {
      field: "strategyreadiness",
      headerName: "Strategy Readiness",
      width: 200,
      renderCell: (params) => (
        <div className="roles-parent-input-container">
          <div className="roles-child-input-container">
            <input
              id={`readiness-read-${params.id}`}
              type="radio"
              name={`readiness-${params.id}`}
              value="read"
              checked={params.value === "read"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "strategyreadiness")
              }
            />
            <label
              htmlFor={`readiness-read-${params.id}`}
              className="roles-radio-label"
            >
              R
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`readiness-write-${params.id}`}
              type="radio"
              name={`readiness-${params.id}`}
              value="write"
              checked={params.value === "write"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "strategyreadiness")
              }
            />
            <label
              htmlFor={`readiness-write-${params.id}`}
              className="roles-radio-label"
            >
              W
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`readiness-admin-${params.id}`}
              type="radio"
              name={`readiness-${params.id}`}
              value="admin"
              checked={params.value === "admin"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "strategyreadiness")
              }
            />
            <label
              htmlFor={`readiness-admin-${params.id}`}
              className="roles-radio-label"
            >
              A
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`readiness-none-${params.id}`}
              type="radio"
              name={`readiness-${params.id}`}
              value="none"
              checked={params.value === "none"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "strategyreadiness")
              }
            />
            <label
              htmlFor={`readiness-none-${params.id}`}
              className="roles-radio-label"
            >
              N
            </label>
          </div>
        </div>
      ),
    },
    {
      field: "xlgtmstrategyplan",
      headerName: "XL GTM Strategy Plan",
      width: 200,
      renderCell: (params) => (
        <div className="roles-parent-input-container">
          <div className="roles-child-input-container">
            <input
              id={`xlgtmstrategyplan-read-${params.id}`}
              type="radio"
              name={`xlgtmstrategyplan-${params.id}`}
              value="read"
              checked={params.value === "read"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "xlgtmstrategyplan")
              }
            />
            <label
              htmlFor={`xlgtmstrategyplan-read-${params.id}`}
              className="roles-radio-label"
            >
              R
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`xlgtmstrategyplan-write-${params.id}`}
              type="radio"
              name={`xlgtmstrategyplan-${params.id}`}
              value="write"
              checked={params.value === "write"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "xlgtmstrategyplan")
              }
            />
            <label
              htmlFor={`xlgtmstrategyplan-write-${params.id}`}
              className="roles-radio-label"
            >
              W
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`xlgtmstrategyplan-admin-${params.id}`}
              type="radio"
              name={`xlgtmstrategyplan-${params.id}`}
              value="admin"
              checked={params.value === "admin"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "xlgtmstrategyplan")
              }
            />
            <label
              htmlFor={`xlgtmstrategyplan-admin-${params.id}`}
              className="roles-radio-label"
            >
              A
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`xlgtmstrategyplan-none-${params.id}`}
              type="radio"
              name={`xlgtmstrategyplan-${params.id}`}
              value="none"
              checked={params.value === "none"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "xlgtmstrategyplan")
              }
            />
            <label
              htmlFor={`xlgtmstrategyplan-none-${params.id}`}
              className="roles-radio-label"
            >
              N
            </label>
          </div>
        </div>
      ),
    },
    {
      field: "xlgtmworkboard",
      headerName: "XL GTM Workboard",
      width: 200,
      renderCell: (params) => (
        <div className="roles-parent-input-container">
          <div className="roles-child-input-container">
            <input
              id={`xlgtmworkboard-read-${params.id}`}
              type="radio"
              name={`xlgtmworkboard-${params.id}`}
              value="read"
              checked={params.value === "read"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "xlgtmworkboard")
              }
            />
            <label
              htmlFor={`xlgtmworkboard-read-${params.id}`}
              className="roles-radio-label"
            >
              R
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`xlgtmworkboard-write-${params.id}`}
              type="radio"
              name={`xlgtmworkboard-${params.id}`}
              value="write"
              checked={params.value === "write"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "xlgtmworkboard")
              }
            />
            <label
              htmlFor={`xlgtmworkboard-write-${params.id}`}
              className="roles-radio-label"
            >
              W
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`xlgtmworkboard-admin-${params.id}`}
              type="radio"
              name={`xlgtmworkboard-${params.id}`}
              value="admin"
              checked={params.value === "admin"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "xlgtmworkboard")
              }
            />
            <label
              htmlFor={`xlgtmworkboard-admin-${params.id}`}
              className="roles-radio-label"
            >
              A
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`xlgtmworkboard-none-${params.id}`}
              type="radio"
              name={`xlgtmworkboard-${params.id}`}
              value="none"
              checked={params.value === "none"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "xlgtmworkboard")
              }
            />
            <label
              htmlFor={`xlgtmworkboard-none-${params.id}`}
              className="roles-radio-label"
            >
              N
            </label>
          </div>
        </div>
      ),
    },
    {
      field: "xlgtminitiativetracker",
      headerName: "XL GTM Initiative Tracker",
      width: 200,
      renderCell: (params) => (
        <div className="roles-parent-input-container">
          <div className="roles-child-input-container">
            <input
              id={`xlgtminitiativetracker-read-${params.id}`}
              type="radio"
              name={`xlgtminitiativetracker-${params.id}`}
              value="read"
              checked={params.value === "read"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "xlgtminitiativetracker")
              }
            />
            <label
              htmlFor={`xlgtminitiativetracker-read-${params.id}`}
              className="roles-radio-label"
            >
              R
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`xlgtminitiativetracker-write-${params.id}`}
              type="radio"
              name={`xlgtminitiativetracker-${params.id}`}
              value="write"
              checked={params.value === "write"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "xlgtminitiativetracker")
              }
            />
            <label
              htmlFor={`xlgtminitiativetracker-write-${params.id}`}
              className="roles-radio-label"
            >
              W
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`xlgtminitiativetracker-admin-${params.id}`}
              type="radio"
              name={`xlgtminitiativetracker-${params.id}`}
              value="admin"
              checked={params.value === "admin"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "xlgtminitiativetracker")
              }
            />
            <label
              htmlFor={`xlgtminitiativetracker-admin-${params.id}`}
              className="roles-radio-label"
            >
              A
            </label>
          </div>
          <div className="roles-child-input-container">
            <input
              id={`xlgtminitiativetracker-none-${params.id}`}
              type="radio"
              name={`xlgtminitiativetracker-${params.id}`}
              value="none"
              checked={params.value === "none"}
              onChange={(e) =>
                handleFieldChange(e, params.id, "xlgtminitiativetracker")
              }
            />
            <label
              htmlFor={`xlgtminitiativetracker-none-${params.id}`}
              className="roles-radio-label"
            >
              N
            </label>
          </div>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.name;

  const content = (
    <> <RolesComponent
    userDataWithIds={userDataWithIds}
    getRowId={getRowId}
    columns={columns}
    handleSave={handleSave}
  />
  <ToastContainer
    position="bottom-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
  />
    </>
  )
  return <HomeContainer content={content} />;
}

export default RolesContainer;

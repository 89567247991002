import React, { useState, useEffect } from "react";
import HomeComponent from "../components/HomeComponent";
import { useDispatch, useSelector } from "react-redux";
import { getMenuItems } from "../store/actions";
import { useLocation } from "react-router-dom";

function HomeContainer(props) {
  const toggle = () => setIsOpen(!isOpen);
  const [isOpen, setIsOpen] = useState(props.isMenuCollapse ? false : true);
  const [menuItems, setMenuItems] = useState();
  const [username, setUserName] = useState('')
  const fetchGetMenuItemsDetails = useSelector(
    (state) => state.fetchGetMenuItemsDetails
  );
  const currentPath = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMenuItems());
  }, []);
  useEffect(() => {
    if (fetchGetMenuItemsDetails) {
      setMenuItems(fetchGetMenuItemsDetails.message);
      setUserName(fetchGetMenuItemsDetails.logged_in_user)
    }
  }, [fetchGetMenuItemsDetails]);
  // const menuItemsData = [
  //   {
  //     id: "d16acda4c7",
  //     title: "Dashboard",
  //     url: "",
  //     icon: "/files/dashboard1ed6c1.svg",
  //     is_menu: 1,
  //     show_sub_menu: 0,
  //     template_link: null,
  //   },
  //   {
  //     id: "527ab680b0",
  //     title: "User Management",
  //     url: "usermanagement",
  //     icon: "/files/gtmexecution.svg",
  //     is_menu: 0,
  //     show_sub_menu: 0,
  //     template_link: null,
  //   },
  //   {
  //     id: "612fce999f",
  //     title: "Role Management",
  //     url: "rolemanagement",
  //     icon: "/files/knowledgebase.svg",
  //     is_menu: 1,
  //     show_sub_menu: 0,
  //     template_link: null,
  //   },
  //   {
  //     id: "f8e41845d1",
  //     title: "Integrations",
  //     url: "integrations",
  //     icon: "/files/building.svg",
  //     is_menu: 0,
  //     show_sub_menu: 0,
  //     template_link: null,
  //   },
  // ];
  const [dashboardUrlMatching, setDashboardUrlMatching] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (itemName) => {
    setIsHovered(true);
    setHoveredItem(itemName);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
    setHoveredItem(null);
  };
  useEffect(() => {
    // Get the current URL
    const currentURL = window.location.href;

    if (currentURL.includes("/dashboard")) {
      setDashboardUrlMatching(true);
    }
  }, []);
  return (
    <HomeComponent
      isOpen={isOpen}
      toggle={toggle}
      menuItems={menuItems}
      isHovered={isHovered}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      hoveredItem={hoveredItem}
      username = {username}
      dashboardUrlMatching = {dashboardUrlMatching}
      content = {props.content}
    />
  );
}

export default HomeContainer;

import React from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  NavLink,
} from "react-router-dom";
import LoginContainer from "./containers/LoginContainer";
import "./App.css";
import DashboardContainer from "./containers/DashboardContainer";
import UsersContainer from "./containers/UsersContainer";
import RolesContainer from "./containers/RolesContainer";
import HomeContainer from "./containers/HomeContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import IntegrationsContainer from "./containers/IntegrationsContainer";
import LDAPContainer from "./containers/Integrations/LDAPContainer";
import GoogleContainer from "./containers/Integrations/GoogleContainer";
import MicrosoftContainer from "./containers/Integrations/MicrosoftContainer";
import { Provider } from "react-redux";
import store from "./store/index.js";
import Logout from "./containers/Logout.js";
import UpdatePassword from "./containers/UpdatePassword.js";
import ForgotPassword from "./containers/ForgotPassword.js";
import "./variables.css";
function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <header>
          <nav></nav>
        </header>
        <Router>
          <Routes>
            <Route index path="/" Component={LoginContainer} />
            <Route path="/login" Component={LoginContainer} />
            <Route path="/update-password" Component={UpdatePassword} />
            <Route path="/forgot-password" Component={ForgotPassword} />
            <Route path="/logout" Component={Logout} />
            <Route path="/dashboard" Component={DashboardContainer} />
            <Route path="/usermanagement" Component={UsersContainer} />
            <Route path="/rolemanagement" Component={RolesContainer} />
            {/* <Route path="integrations" Component={IntegrationsContainer}>
                <Route index path="ldap" Component={LDAPContainer} />
                <Route index path="google" Component={GoogleContainer} />
                <Route index path="microsoft" Component={MicrosoftContainer} />
                <Route index path="projects" />
                <Route index path="crm" />
              </Route> */}
          </Routes>
        </Router>
      </div>
    </Provider>
  );
}

export default App;

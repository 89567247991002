import React from "react";
import { DashboardComponent } from "../components/DashboardComponent";
import HomeContainer from "./HomeContainer";

function DashboardContainer() {
  const content = <DashboardComponent />;
  return <HomeContainer content={content} />;
}

export default DashboardContainer;

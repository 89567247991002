import React, { useEffect, useState } from "react";
import SideBarComponent from "../components/SideBarComponent";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getUserDetails, postUserDetails } from "../store/actions";

const SideBarContainer = ({
  onClose,
  editUser,
  handleSave,
  formData,
  handleInputChange,
  masterData,
  customDepartmentSelected,
  customDesignationSelected,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <SideBarComponent
        editUser={editUser}
        onClose={onClose}
        formData={formData}
        handleSave={handleSave}
        handleInputChange={handleInputChange}
        masterData={masterData}
        customDepartmentSelected={customDepartmentSelected}
        customDesignationSelected={customDesignationSelected}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default SideBarContainer;

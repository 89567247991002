import {
  FORGOT_USER_PASSWORD_SUCCESS,
  GET_GDRIVE_DETAILS_SUCCESS,
  GET_MENU_ITEMS_SUCCESS,
  GET_USERS_SUCCESS,
  GET_USER_DETAILS_SUCCESS,
  LOADING_START,
  LOGIN_SUCCESS,
  POST_ADMIN_ROLE_ASSIGNMENT_DETAILS_SUCCESS,
  POST_GDRIVE_DETAILS_SUCCESS,
  POST_GDRIVE_SERVICE_SUCCESS,
  POST_USER_DETAILS_SUCCESS,
  UPDATE_USER_PASSWORD_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  formSubmitted: false,
  userDetails: [],
  isLoggedin: false,
  isSessionLive: false,
  isSessionAPIError: false,
  isInternalServerError: false,
  isSessionExpiry: false,
  showLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        showLoading: true,
      };
    case LOGIN_SUCCESS:
      localStorage.clear();
      localStorage.setItem("userDetails", JSON.stringify(action.userDetails));
      return {
        ...state,
        userDetails: action.userDetails,
        submitted: action.submitted,
        isLoginFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        fetchGetUserDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_USER_DETAILS_SUCCESS:
      return {
        ...state,
        fetchPostUserData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_MENU_ITEMS_SUCCESS:
      return {
        ...state,
        fetchGetMenuItemsDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        fetchGetUsersDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_ADMIN_ROLE_ASSIGNMENT_DETAILS_SUCCESS:
      return {
        ...state,
        fetchPostAdminRoleAssingmentData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_GDRIVE_DETAILS_SUCCESS:
      return {
        ...state,
        fetchGetGdriveDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_GDRIVE_DETAILS_SUCCESS:
      return {
        ...state,
        fetchPostGdriveDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
      case POST_GDRIVE_SERVICE_SUCCESS:
      return {
        ...state,
        fetchPostGdriveService: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
      case UPDATE_USER_PASSWORD_SUCCESS:
        return {
          ...state,
          fetchuserUpdatePasswordDetails: action.response,
          isUpdatePasswordFetched: true,
          isSessionLive: true,
          isSessionExpiry: false,
          isSessionAPIError: false,
          isInternalServerError: false,
          showLoading: false,
        };
      case FORGOT_USER_PASSWORD_SUCCESS:
        return {
          ...state,
          fetchuserForgotPasswordDetails: action.response,
          isForgotPasswordFetched: true,
          isSessionLive: true,
          isSessionExpiry: false,
          isSessionAPIError: false,
          isInternalServerError: false,
          showLoading: false,
        };
    default:
      return state;
  }
};
export default reducer;

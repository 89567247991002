export const LOADING_START = "LOADING_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOGIN_INITIATE = "USER_LOGIN_INITIATE";
export const EXCEPTION_ERROR = "EXCEPTION_ERROR";
export const RESET_EXCEPTION_ERROR = "RESET_EXCEPTION_ERROR";
export const SESSION_API_ERROR = "SESSION_API_ERROR";
export const SESSION_EXPIRE_ERROR = "SESSION_EXPIRE_ERROR";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const POST_USER_DETAILS = "POST_USER_DETAILS";
export const POST_USER_DETAILS_SUCCESS = "POST_USER_DETAILS_SUCCESS";
export const GET_MENU_ITEMS = "GET_MENU_ITEMS";
export const GET_MENU_ITEMS_SUCCESS = "GET_MENU_ITEMS_SUCCESS";
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const POST_ADMIN_ROLE_ASSIGNMENT_DETAILS = "POST_ADMIN_ROLE_ASSIGNMENT_DETAILS";
export const POST_ADMIN_ROLE_ASSIGNMENT_DETAILS_SUCCESS = "POST_ADMIN_ROLE_ASSIGNMENT_DETAILS_SUCCESS";
export const GET_GDRIVE_DETAILS = "GET_GDRIVE_DETAILS";
export const GET_GDRIVE_DETAILS_SUCCESS = "GET_GDRIVE_DETAILS_SUCCESS";
export const POST_GDRIVE_DETAILS = "POST_GDRIVE_DETAILS";
export const POST_GDRIVE_DETAILS_SUCCESS = "POST_GDRIVE_DETAILS_SUCCESS";
export const POST_GDRIVE_SERVICE = "POST_GDRIVE_SERVICE";
export const POST_GDRIVE_SERVICE_SUCCESS = "POST_GDRIVE_SERVICE_SUCCESS";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const FORGOT_USER_PASSWORD = "FORGOT_USER_PASSWORD";
export const FORGOT_USER_PASSWORD_SUCCESS = "FORGOT_USER_PASSWORD_SUCCESS";
import React from "react";
import Image from "../assets/images/XL.png";
import { Form, Button } from "react-bootstrap";
import "./LoginComponent.css";
import Loader from "./Loader.js";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import { Link } from "react-router-dom";

function LoginComponent({
  username,
  setUsername,
  setPassword,
  handleLogin,
  showError,
  showLoader,
  passwordVisible,
  togglePasswordVisibility,
}) {
  return (
    <>
      {showLoader && <Loader />}
      <div
        className="pagecenter loginForm"
        style={{ padding: "10%", width: "100%" }}
      >
        <Form
          className="modal-content animate"
          onSubmit={handleLogin}
          style={{ width: "500px", maxWidth: "500px" }}
        >
          <div
            className="container1"
            style={{ width: "90%", paddingBottom: "5%", paddingLeft: "10%" }}
          >
            <h3 style={{ textAlignVertical: "center", textAlign: "center" }}>
              <img style={{ width: "7%" }} src={Image} />
              Excelrate Admin
            </h3>
            <Form.Group size="lg" controlId="text">
              <Form.Control
                autoFocus
                name="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                placeholder="Enter Username"
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group size="lg" controlId="password">
              <Form.Control
                name="password"
                style={{ position: "relative" }}
                type={passwordVisible ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter Password"
              />
              <span
                className="password-toggle-login"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </span>
            </Form.Group>
            {showError && (
              <span style={{ color: "red" }}>Invalid Username/Password</span>
            )}
            <Button size="lg" type="submit" style={{ height: "50px" }}>
              Login
            </Button>
            <div className="forgot-password-login">
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default LoginComponent;

import {
  FORGOT_USER_PASSWORD,
  GET_GDRIVE_DETAILS,
  GET_GDRIVE_SERVICE,
  GET_MENU_ITEMS,
  GET_USERS,
  GET_USER_DETAILS,
  POST_ADMIN_ROLE_ASSIGNMENT_DETAILS,
  POST_GDRIVE_DETAILS,
  POST_GDRIVE_SERVICE,
  POST_USER_DETAILS,
  UPDATE_USER_PASSWORD,
  USER_LOGIN_INITIATE,
} from "../actions/actionTypes";
import {
  getGdriveDetailsSaga,
  getGdriveServiceSaga,
  getMenuItemsSaga,
  getUserDetailsSaga,
  getUsersSaga,
  postAdminRoleAssignmentDetailsSaga,
  postGdriveDetailsSaga,
  postGdriveServiceSaga,
  postUserDetailsSaga,
  userForgotPasswordSaga,
  userLoginSaga,
  userUpdatePasswordSaga,
} from "./API";
import { takeEvery } from "redux-saga/effects";

export function* root() {
  yield takeEvery(USER_LOGIN_INITIATE, userLoginSaga);
  yield takeEvery(GET_USER_DETAILS, getUserDetailsSaga);
  yield takeEvery(POST_USER_DETAILS, postUserDetailsSaga);
  yield takeEvery(GET_MENU_ITEMS, getMenuItemsSaga);
  yield takeEvery(GET_USERS, getUsersSaga);
  yield takeEvery(
    POST_ADMIN_ROLE_ASSIGNMENT_DETAILS,
    postAdminRoleAssignmentDetailsSaga
  );
  yield takeEvery(GET_GDRIVE_DETAILS, getGdriveDetailsSaga);
  yield takeEvery(
    POST_GDRIVE_DETAILS,
    postGdriveDetailsSaga
  );
  yield takeEvery(
    POST_GDRIVE_SERVICE,
    postGdriveServiceSaga
  );
  yield takeEvery(UPDATE_USER_PASSWORD, userUpdatePasswordSaga);
  yield takeEvery(FORGOT_USER_PASSWORD, userForgotPasswordSaga);
}

import React, { useState, useEffect } from "react";
import UsersComponent from "../components/UsersComponent";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserDetails,
  postUserDetails,
  resetExceptionError,
} from "../store/actions";
import * as cusAlert from "./Alert.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideBarContainer from "./SideBarContainer.js";
import HomeContainer from "./HomeContainer.js";

function UsersContainer() {
  const dispatch = useDispatch();
  const [Users, setUsers] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const fetchGetUserDetails = useSelector((state) => state.fetchGetUserDetails);
  useEffect(() => {
    dispatch(getUserDetails());
  }, []);
  const [masterData, setmasterData] = useState("");
  const [customDepartmentSelected, setCustomDepartmentSelected] = useState("");
  const [customDesignationSelected, setCustomDesignationSelected] =
    useState("");

  const fetchPostUserData = useSelector((state) => state.fetchPostUserData);

  useEffect(() => {
    if (fetchGetUserDetails) {
      setmasterData(fetchGetUserDetails);
    }
  }, [fetchGetUserDetails]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    selectedGender: "",
    email: "",
    department: "",
    status: "",
    designation: "",
    reporting: "",
    selectBI: "",
  });
  useEffect(() => {
    if (selectedRows) {
      const firstDataItem = selectedRows[0];
      if (firstDataItem) {
        setFormData({
          firstName: firstDataItem.firstName || "",
          lastName: firstDataItem.lastName || "",
          email: firstDataItem.email || "",
          selectedGender: firstDataItem.gender || "",
          status: firstDataItem.status || "",
          department: firstDataItem.department,
          designation: firstDataItem.designation || "",
          reporting: firstDataItem.reports_to || "",
          selectBI: firstDataItem.selectBI || "",
        });
      }
    }
  }, [selectedRows]);

  const handleInputChange = (field, value) => {
    if (value === "Add Custom Department") {
      setCustomDepartmentSelected(true);
    } else if (value === "Add Custom Designation") {
      setCustomDesignationSelected(true);
    }
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  useEffect(() => {
    if (fetchGetUserDetails) {
      setUsers(fetchGetUserDetails.employee_data);
    }
  }, [fetchGetUserDetails]);
  const handleCloseSideBar = () => {
    setSidebarOpen(false);
    setEditUser(false);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      selectedGender: "",
      department: "",
      designation: "",
      reporting: "",
      selectBI: "",
    });
  };
  const handleAddClick = () => {
    setSidebarOpen(true);
  };

  useEffect(() => {
    if (fetchPostUserData && fetchPostUserData.status === 200) {
      if (fetchPostUserData.action === "add") {
        if (fetchPostUserData.data) {
          const data = fetchPostUserData.data;
          const newFunction = {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            department: data.department,
            status: data.status,
            designation: data.designation,
            gender: data.gender,
            reports_to: data.reporting,
            selectBI: data.selectBI,
          };
          setUsers([...Users, newFunction]);
          toast.success(fetchPostUserData.message);
        }
      } else if (fetchPostUserData.action === "edit") {
        if (fetchPostUserData.data) {
          const latest_data = fetchPostUserData.data;
          const data = latest_data[0];
          const newFunction = {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            department: data.department,
            status: data.status,
            designation: data.designation,
            gender: data.gender,
            reports_to: data.reporting,
            selectBI: data.selectBI,
          };
          const userIndex = Users?.findIndex(
            (user) => user.id === newFunction.id
          );
          if (userIndex !== -1) {
            // If the user with the same id exists, update it
            setUsers((prevUsers) => {
              const updatedUsers = [...prevUsers];
              updatedUsers[userIndex] = newFunction;
              return updatedUsers;
            });
            toast.success(fetchPostUserData.message);
          }
        }
      } else if (
        fetchPostUserData.action === "inactive" &&
        fetchPostUserData.data
      ) {
        const latestData = fetchPostUserData.data;
        setUsers((prevUsers) => {
          const updatedUsers = prevUsers.map((user) => {
            const matchingData = latestData.find((data) => data.id === user.id);
            if (matchingData) {
              return {
                ...user,
                status: matchingData.status,
              };
            }
            return user;
          });

          return updatedUsers;
        });

        toast.success(fetchPostUserData.message);
      } else {
        toast.error(fetchPostUserData.message);
      }
    }
  }, [fetchPostUserData]);

  const handleSave = () => {
    const requiredFields = {
      firstName: "",
      email: "",
      selectedGender: "",
      reporting: "",
    };
    let isFormValid = true;
    for (let field in requiredFields) {
      if (requiredFields.hasOwnProperty(field)) {
        // Check if the field is empty, undefined, or not a valid type
        if (
          !formData[field] ||
          (typeof formData[field] !== "string" &&
            !Array.isArray(formData[field])) ||
          (typeof formData[field] === "string" && formData[field].trim() === "")
        ) {
          toast.error(`${field} cannot be empty!`);
          isFormValid = false;
          break; // Exit the loop if any field is empty or not a valid type
        }

        // Check email format if the field is 'email'
        if (field === "email" && typeof formData.email === "string") {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(formData.email)) {
            toast.error("Please enter a valid email address");
            isFormValid = false;
            break; // Exit the loop if email is invalid
          }
        }
      }
    }
    if (isFormValid) {
      if (editUser) {
        let latest_data = {
          ...formData,
          id: selectedRows[0].id,
        };
        dispatch(postUserDetails("edit", true, latest_data));
      } else {
        dispatch(postUserDetails("add", true, formData));
      }
      handleCloseSideBar();
    }
  };

  const handleInActiveClick = () => {
    cusAlert.default.warning({
      title: "Warning",
      // html: ISError,
      text: "Are you sure do you want to In-Active the User?",
      icon: "warning",
      showCancelButton: true,
      preConfirm: (isConfirm) => {
        if (isConfirm) {
          if (selectedRows.length > 0) {
            dispatch(postUserDetails("inactive", false, selectedRows));
          } else {
            toast.error("Please select the user to inactive!!");
            dispatch(resetExceptionError());
          }
        }
      },
    });
  };
  const handleCellClick = (e, params) => {
    const id = params.row.id;
    const data = [];
    data.push(params.row);
    setSelectedRows(data);
    setSidebarOpen(true);
    setEditUser(true);
  };

  const handleRowSelectionModelChange = (ids) => {
    const selectedIDs = new Set(ids);
    const newSelectedRows = Users.filter((row) => selectedIDs.has(row.id));
    setSelectedRows(newSelectedRows);
  };
  const content = (
    <>
      {" "}
      <UsersComponent
        handleCellClick={handleCellClick}
        rows={Users}
        handleRowSelectionModelChange={handleRowSelectionModelChange}
        handleAddClick={handleAddClick}
        handleInActiveClick={handleInActiveClick}
      />
      {sidebarOpen && (
        <SideBarContainer
          data={selectedRows}
          onClose={handleCloseSideBar}
          editUser={editUser}
          handleSave={handleSave}
          formData={formData}
          handleInputChange={(field, value) => handleInputChange(field, value)}
          masterData={masterData}
          customDepartmentSelected={customDepartmentSelected}
          customDesignationSelected={customDesignationSelected}
        />
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
  return <HomeContainer content={content} />;
}
export default UsersContainer;

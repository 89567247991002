import React, { useState, useEffect } from "react";
import LoginComponent from "../components/LoginComponent";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie"; // Import the js-cookie library
import { userLogin } from "../store/actions/index.js";

function LoginContainer() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const isLoggedin = useSelector((state) => state.isLoginFetched);
  const isSubmitted = useSelector((state) => state.submitted);
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const response = useSelector((state) => JSON.stringify(state.userDetails));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    const lastLoginUsername = Cookies.get("lastLoginUsername");
    if (lastLoginUsername) {
      setUsername(lastLoginUsername);
    }
  }, []);
  useEffect(() => {
    if (isLoggedin) {
      var response = JSON.parse(localStorage.getItem("userDetails"));
      if (response.ok) {
        if (response) {
          if (response.data["message"] === "Logged In") {
            localStorage.setItem(
              "token",
              response.data["api_key"] + ":" + response.data["api_secret"]
            );
            localStorage.setItem("authenticated", true);
            localStorage.setItem("full_name", response.data["full_name"]);
            // Cookies.set("sid", response.data["sid"]);
            navigate("/dashboard");
          } else {
            console.log("1")
            setShowLoader(false);
            setShowError(true);
          }
        }
      } else if ((response.status = 403)) {
        console.log("3")

        setShowLoader(false);
        setShowError(true);
      } else {
        console.log("3")

        setShowLoader(false);
        setShowError(true);
      }
    }
  }, [isLoggedin, isSubmitted]);
  const handleLogin = (event) => {
    event.preventDefault();
    setShowLoader(true);
    setShowError(false);
    setSubmitted(!submitted);
    console.log("clicked");
    console.log("submitted", submitted);
    dispatch(userLogin(username, password, submitted, true));
    Cookies.set("lastLoginUsername", username);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <LoginComponent
      showLoader={showLoader}
      showError={showError}
      username={username}
      password={password}
      setUsername={setUsername}
      setPassword={setPassword}
      handleLogin={handleLogin}
      passwordVisible= {passwordVisible}
      togglePasswordVisibility = {()=>togglePasswordVisibility()}
    />
  );
}

export default LoginContainer;

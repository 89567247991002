import "./Loader.css";
const Loader = () => {
  return (
    <div id="backdrop">
      <div className="text-center loading">
        <div className="spinner-border" role="status"></div>
      </div>
    </div>
  );
};
export default Loader;
